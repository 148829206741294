import React from "react"

const ContactInfo = () => (
  <footer id="contact-info" className="contact-info l-container">
    <p className="contact-info__email--alt">Contact me</p>
    <p className="contact-info__email">sashazm85@gmail.com</p>
  </footer>
)

export default ContactInfo
